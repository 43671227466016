import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const themesConfig = {
  default: {
    palette: {
      type: 'light',
      primary: {
        light: '#6ac33e',
        main: '#45B40E',
        dark: '#307d09',
      },
      secondary: {
        light: '#6ec742',
        main: '#4ABA13',
        dark: '#33820d',
        contrastText: '#fff',
      },
      error: {
        main: red[800],
      },
    },
    status: {
      danger: red[500],
    },
    overrides: {
      MuiButton: {
        label: {},
      },
    },
  },
};

export default createMuiTheme(themesConfig.default);
