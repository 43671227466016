import Policy from '.';
import { Redirect } from 'react-router-dom';

export const PolicyConfig = {
  routes: [
    {
      path: '/privacy-policy',
      exact: true,
      component: Policy,
    },
    {
      path: '/privacy',
      component: () => <Redirect to='/privacy-policy' />,
    },
  ],
};
