import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { withRouter, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Toolbar,
  Icon,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Popover,
} from '@material-ui/core';
import { Button } from '../../../../@easygo';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from './../Drawer/Drawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  banner: {
    flexGrow: 1,
    backgroundImage: 'url(/assets/banner/bg-1.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      minHeight: '1120px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '480px',
    },
    '& .top-banner': {
      backgroundImage: 'url(/assets/banner/bg-2.png)',
      backgroundRepeat: 'no-repeat',
      paddingTop: theme.spacing(12),
      [theme.breakpoints.up('sm')]: {
        minHeight: '768px',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '648px',
      },
    },
  },
  social: {
    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  menuButton: {
    '&.hide': { display: 'none' },
    [theme.breakpoints.up('sm')]: {},
  },
  button: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  avatar: { background: theme.palette.secondary.main },
  emailButton: {
    '& .MuiButton-startIcon': {
      textTransform: 'UPPERCASE',
    },
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  listIcon: { minWidth: 40 },
  navigation: {
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
      },
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: {
      minHeight: '48px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '48px',
    },
  },
}));

export default withRouter(function Header(props) {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(({ auth }) => auth.user.data);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _open = Boolean(anchorEl);
  const id = _open ? 'auth-popover' : undefined;

  return (
    <div className='w-full'>
      <AppBar
        position='absolute'
        color='primary'
        elevation={0}
        className={clsx(classes.appBar /* { [classes.appBarShift]: open }*/)}
      >
        <Toolbar>
          <div className='w-full md:max-w-7xl mx-auto flex items-center justify-between text-sm'>
            <div className='flex items-center'>
              <img
                className={clsx('img h-16 mr-8 my-4')}
                src='/assets/logo.svg'
                alt='logo'
              />
            </div>

            <div className='flex items-center space-x-8'>
              <div
                className={clsx(
                  classes.navigation,
                  'sm:flex sm:flex-1 flex-grow md:space-x-12 sm:space-x-1 hidden'
                )}
              >
                <Link to='/'>Home</Link>
                <HashLink smooth to='/#footer'>
                  About Us
                </HashLink>
                <HashLink smooth to='/#features'>
                  Features
                </HashLink>
                <HashLink smooth to='/#payment-partners'>
                  Partners
                </HashLink>
                <HashLink smooth to='/#footer'>
                  Contact
                </HashLink>
              </div>
              {user.role ? (
                <Fragment>
                  <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    color='inherit'
                    className={classes.emailButton}
                    startIcon={
                      <Avatar className={classes.avatar}>
                        {user.email[0]}
                      </Avatar>
                    }
                  >
                    {user.email}
                    <Icon
                      className='text-16 ml-4 hidden sm:flex tran'
                      variant='action'
                    >
                      keyboard_arrow_down
                    </Icon>
                  </Button>
                  <Popover
                    id={id}
                    open={_open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Fragment>
                      <MenuItem component={Link} to='/profile'>
                        <ListItemIcon className={classes.listIcon}>
                          <Icon>person</Icon>
                        </ListItemIcon>
                        <ListItemText className='pl-0' primary='Profile' />
                      </MenuItem>
                      <MenuItem component={Link} to='/logout'>
                        <ListItemIcon className={classes.listIcon}>
                          <Icon>lock</Icon>
                        </ListItemIcon>
                        <ListItemText className='pl-0' primary='Logout' />
                      </MenuItem>
                    </Fragment>
                  </Popover>
                </Fragment>
              ) : (
                <Fragment>
                  <ul
                    className={clsx(
                      classes.social,
                      'flex space-x-1 md:space-x-2'
                    )}
                  >
                    <li className='border-gray-400'>
                      <IconButton
                        target='_blank'
                        href='https://www.facebook.com/easygo.app1'
                      >
                        <Icon>
                          <img
                            className='h-full w-full'
                            src='/assets/social/facebook-1.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </li>
                    <li>
                      <IconButton
                        target='_blank'
                        href='https://twitter.com/_easygoapp'
                      >
                        <Icon>
                          <img
                            className='h-full w-full'
                            src='/assets/social/twitter-1.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </li>
                    <li>
                      <IconButton
                        target='_blank'
                        href='https://www.instagram.com/_easygoapp/'
                      >
                        <Icon>
                          <img
                            className='h-full w-full'
                            src='/assets/social/instagram-1.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </li>
                  </ul>
                </Fragment>
              )}

              <div className='flex md:hidden'>
                <IconButton
                  className={clsx(
                    classes.menuButton,
                    { hide: open },
                    'focus:outline-none'
                  )}
                  edge='start'
                  color='secondary'
                  aria-label='menu'
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />

      <Drawer open={open} handleDrawerClose={handleDrawerClose} user={user} />
    </div>
  );
});
