import Test from '.';

export const TestConfig = {
  routes: [
    {
      path: '/test',
      component: Test,
    },
  ],
};
