const testimonies = [
  {
    name: 'Dr. Charlotte Ndiribe',
    description:
      'It creates ease in management of various departments and sections of an organization and it is in two categories, ERP and its stand alone application modules which are; HR, Accounting, CRM, Document Management, Learning Management System.',
    company: 'Life Fellow',
    avatar: 'carl.jpg',
  },
  {
    name: 'UK Eke ( MFR ) GMD FBN Holdings',
    description:
      'It creates ease in management of various departments and sections of an organization and it is in two categories, ERP and its stand alone application modules which are; HR, Accounting, CRM, Document Management, Learning Management System.',
    company: 'Gold Corporate Member',
    avatar: 'nora.jpg',
  },
  {
    name: 'Jeff Ewing - Chairman/MD',
    description:
      'It creates ease in management of various departments and sections of an organization and it is in two categories, ERP and its stand alone application modules which are; HR, Accounting, CRM, Document Management, Learning Management System.',
    company: 'Cheveron Nigeria Limited',
    avatar: 'vela.jpg',
  },
];

const features_left = [
  {
    title: 'Go Data and Go Airtime',
    description: 'Recharge airtime and data with no stress at all.',
  },
  {
    title: 'Go Power',
    description: 'Making payments for electicity just got easy with Easygo.',
  },
  {
    title: 'Go Cable',
    description:
      'You can pay for subscription for your DSTV, GOTV, STARTIMES, NETFLIX etc.',
  },
];

const features_right = [
  {
    title: 'Go Booking',
    description:
      'Book flight, hotel and Transport services from the application.',
  },
  {
    title: 'Go Religious',
    description:
      'Religious organizations on Easygo can receive donations, offerings and tithe from registered users.',
  },
  {
    title: 'Go Transfer',
    description:
      'This Feature enables wallet transfer between registered users.',
  },
];

const counters = [
  {
    title: 'Trust',
    text:
      'At EasyGo your trust is foremost, your money is yours until you get the service you paid for.',
    icon: 'trust.svg',
  },
  {
    title: 'Faster & Smoother',
    text: 'Make the most of the enriched in-app experience',
    icon: 'fast.svg',
  },
  {
    title: '24/7 Customer Care',
    text: 'Get quicker resolution on queries related to EasyGo',
    icon: 'customer-care.svg',
  },
  {
    title: 'Cashback & Offers',
    text: 'Get exicting bonuses and offers',
    icon: 'cashback.svg',
  },
  {
    title: 'Security',
    text: 'All your financial data confidential & secured.',
    icon: 'security.svg',
  },
];

export { features_left, features_right, testimonies, counters };
