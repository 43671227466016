import React from 'react';
import { renderRoutes } from 'react-router-config';
import Header from './components/Header/Header';
import Header2 from './components/Header2/Header';
import Footer from './components/Footer/Footer';

export default function Layout(props) {
  const { route, location } = props;
  const AppHeader = location.pathname === '/' ? Header : Header2;

  return (
    <div className='w-full'>
      <AppHeader />

      <main className='w-full'>{renderRoutes(route.routes)}</main>

      <div className='w-full'>
        <Footer />
      </div>
    </div>
  );
}
