import React from 'react';

function JobView({ job }) {
  console.log(job, 'Rendering Updating job view');

  return (
    <div>
      <p>I earned {job} salary</p>
    </div>
  );
}

export default JobView;
