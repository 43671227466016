import Home from '.';

export const HomeConfig = {
  routes: [
    {
      path: '/',
      exact: true,
      component: Home,
    },
  ],
};
