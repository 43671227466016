import React, { useState, useEffect, useRef } from 'react';
import Button from './components/Button';
import JobView from './components/JobView';

function Test() {
  const [state, setState] = useState('');
  const [job, setJob] = useState(5000);
  const inputRef = useRef();

  useEffect(() => {}, []);

  const updateJob = () => {
    setJob((prevJob) => prevJob + 1000);
  };

  console.log(inputRef.current, 'inputRef');

  return (
    <div className='my-10'>
      <input
        type='text'
        value={state}
        placeholder='Enter your name'
        ref={inputRef}
        onChange={(e) => setState(e.target.value)}
      />

      <JobView job={job} />
      <Button updateJob={updateJob} />
    </div>
  );
}

export default Test;
