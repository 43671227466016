import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url(/assets/banner/footer-bg.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top right',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundPosition: 'center',
    },
  },
  list: {
    '& .MuiListItem-root': {},
    '& .MuiListItemIcon-root': {
      minWidth: 40,
      color: theme.palette.background.paper,
    },
  },
  social: {
    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default function Footer(props) {
  const classes = useStyles(props);

  return (
    <footer
      id='footer'
      className={clsx(
        classes.root,
        'flex flex-col gap-y-2 text-white text-sm pt-48'
      )}
    >
      <div className='max-w-6xl mx-auto h-full md:h-96'>
        <div className='grid grid-cols-1 md:grid-cols-12 gap-x-8 gap-y-8 md:gap-y-0 px-8 pb-8 mt-8'>
          <div className='col-span-4'>
            <h3 className='text-2xl text-white font-bold mb-3'>EasyGo</h3>
            <p>
              We started EasyGo with you in mind to make payments easy, secure
              and reliable. We are creating a new and unique experience for our
              customers through our automated bill payments system. Therefore
              changing culture around how bills are paid among our audience and
              Africa.
            </p>

            <ul className={clsx(classes.social, 'flex space-x-2 mt-4')}>
              <li className='border-gray-400'>
                <IconButton
                  target='_blank'
                  href='https://www.facebook.com/easygo.app1'
                >
                  <Icon>
                    <img
                      className='h-full w-full'
                      src='/assets/social/facebook-1.svg'
                      alt=''
                    />
                  </Icon>
                </IconButton>
              </li>
              <li>
                <IconButton
                  target='_blank'
                  href='https://twitter.com/_easygoapp'
                >
                  <Icon>
                    <img
                      className='h-full w-full'
                      src='/assets/social/twitter-1.svg'
                      alt=''
                    />
                  </Icon>
                </IconButton>
              </li>
              <li>
                <IconButton
                  target='_blank'
                  href='https://www.instagram.com/_easygoapp/'
                >
                  <Icon>
                    <img
                      className='h-full w-full'
                      src='/assets/social/instagram-1.svg'
                      alt=''
                    />
                  </Icon>
                </IconButton>
              </li>
            </ul>
          </div>

          <div className='col-span-4'>
            <h3 className='text-white uppercase mb-3'>Contact us</h3>
            <List dense className={classes.list}>
              <ListItem disableGutters>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText>
                  Suite 808, 8th Floor, Mandilas Mall,
                  <br />
                  No 96/102, Broad Street, Marina,
                  <br />
                  Lagos Island | Lagos - Nigeria.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText>(+234) 708 895 7111</ListItemText>
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText>info@easygo.com.ng</ListItemText>
              </ListItem>
            </List>
          </div>
          <div className='col-span-4'>
            <h3 className='text-white uppercase mb-3'>
              Subscribe to our Go-letters
            </h3>
            {/*<p className='text-sm text-white'>and get latest news & update</p>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-2 sm:col-span-3'>
                <div className='flex rounded-md shadow-sm mt-4'>
                  <input
                    type='text'
                    name='company_website'
                    id='company_website'
                    className='focus:outline-none focus:border-blue-500 flex-1 block px-2 py-2 w-full rounded-none rounded-l-md sm:text-sm border-gray-50 outline-none border-solid'
                    placeholder='Email'
                  />
                  <button className='inline-flex items-center px-3 rounded-r-md border-0 cursor-pointer border-green-500 border-solid focus:outline-none bg-green-dark text-gray-50 text-sm'>
                    Subscribe
                  </button>
                </div>
              </div>
            </div> */}

            <div id='mc_embed_signup'>
              <form
                action='https://easygo.us1.list-manage.com/subscribe/post?u=a2a5af3d3b92f1b88528be0c6&amp;id=213bbfd35e'
                method='post'
                id='mc-embedded-subscribe-form'
                name='mc-embedded-subscribe-form'
                target='_blank'
                noValidate
              >
                <div id='mc_embed_signup_scroll'>
                  {/* <h2>Subscribe to our Go-letters</h2> */}
                  <div className='indicates-required'>
                    <span className='asterisk'>*</span> indicates required
                  </div>
                  <div className='mc-field-group'>
                    <label htmlFor='mce-EMAIL'>
                      Email Address <span className='asterisk'>*</span>
                    </label>
                    <input
                      type='email'
                      // value=''
                      name='EMAIL'
                      className='required email focus:outline-none'
                      id='mce-EMAIL'
                    />
                  </div>
                  <div className='mc-field-group'>
                    <label htmlFor='mce-FNAME'>First Name </label>
                    <input
                      type='text'
                      // value=''
                      name='FNAME'
                      className='focus:outline-none'
                      id='mce-FNAME'
                    />
                  </div>
                  <div id='mce-responses' className='clear'>
                    <div
                      className='response'
                      id='mce-error-response'
                      style={{ display: 'none' }}
                    ></div>
                    <div
                      className='response'
                      id='mce-success-response'
                      style={{ display: 'none' }}
                    ></div>
                  </div>
                  <div
                    style={{ position: 'absolute', left: '-5000px' }}
                    aria-hidden='true'
                  >
                    <input
                      type='text'
                      name='b_a2a5af3d3b92f1b88528be0c6_213bbfd35e'
                      tabIndex='-1'
                      // value=''
                    />
                  </div>
                  <div className='clear'>
                    <input
                      type='submit'
                      value='Subscribe'
                      name='subscribe'
                      id='mc-embedded-subscribe'
                      className='button'
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        aria-label='copyright'
        className='flex flex-col items-center justify-center p-6 space-y-1 bg-transparent'
      >
        <div className='max-w-6xl mx-auto text-center'>
          <p className='text-white text-sm'>
            Copyright {new Date().getFullYear()} Optisoft Technology Company
            (Nig.) Ltd. All rights reserved. &nbsp;{' '}
            <a className='text-green-dark' href='/privacy-policy'>
              Policy policy
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
