import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withReducer from './../../store/withReducer'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import reducer from './../../auth/store/reducers'
import * as authActions from './../../auth/store/actions'
import {
  Avatar,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
  IconButton,
} from '@material-ui/core'
import products from './products.json'
import { counters } from './mock'

const useStyles = makeStyles((theme) => ({
  banner: {
    minHeight: '360px',
    backgroundImage: "url('/assets/banner/counter-bg.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  header: { color: '#3F6477' },
  card: {
    display: 'flex',
    height: '160px',
    '& .MuiCardContent-root': {},
  },
  listLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiListItemText-root': {
      textAlign: 'right',
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.primary.main,
        flexShrink: 0,
      },
    },
  },
  listRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiListItemText-root': {
      textAlign: 'left',
    },
    '& .MuiListItemIcon-root': {
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.primary.main,
        flexShrink: 0,
      },
    },
  },
}))

function Home(props) {
  const classes = useStyles(props)

  return (
    <div className='w-full'>
      <section
        id='features'
        className='max-w-6xl mx-auto mt-0 md:-mt-16 px-4 py-4 sm:px-6 lg:px-8 text-center'
      >
        <div className='grid grid-cols-6 gap-8 py-2'>
          <div className='col-span-6 md:col-span-46'>
            <h2 className='text-1xl sm:text-2xl text-gray-900 mb-4'>
              Features
            </h2>
            <div className='max-w-md mx-auto'>
              <p className='text-gray-800 leading-normal'>
                EasyGo APP is a Lifestyle payment app that provides easy
                payments for basic human needs we provide most of the basic
                utility services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='about' className='pb-12 pt-4 md:pt-8 bg-white'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-0 md:mt-10'>
            <dl className='grid grid-cols-12'>
              <div className='flex self-stretch col-span-12 md:col-span-4 py-8'>
                <List className={classes.listLeft}>
                  <ListItem className='list-text'>
                    <ListItemText
                      primary='Go Data and Go Airtime'
                      secondary='Recharge airtime and data with no stress at all'
                    />
                    <ListItemIcon className='list-icon'>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/airtime.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Go Power'
                      secondary='Making payments for electicity just got easy with Easygo'
                    />
                    <ListItemIcon>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/power.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Go Cable'
                      secondary='You can pay for subscription for your DSTV, GOTV, STARTIMES, NETFLIX etc'
                    />
                    <ListItemIcon>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/cable.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                </List>
              </div>

              <div className='flex flex-grow col-span-12 md:col-span-4'>
                <img
                  className='w-full h-full'
                  src='/assets/easy-go-infographic.svg'
                  alt=''
                />
              </div>

              <div className='flex col-span-12 md:col-span-4 self-stretch py-8'>
                <List className={classes.listRight}>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/takeoff.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary='Go Booking'
                      secondary='Book flight, hotel and Transport services from the application'
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/religion.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary='Go Religious'
                      secondary='Religious organizations on Easygo can receive donations, offerings and tithe from registered users'
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconButton>
                        <Icon>
                          <img
                            className='h-6'
                            src='/assets/icons/transfer.svg'
                            alt=''
                          />
                        </Icon>
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary='Go Transfer'
                      secondary='This Feature enables wallet transfer between registered users'
                    />
                  </ListItem>
                </List>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <section
        id='stats'
        className={clsx(classes.banner, 'px-4 text-center text-white')}
      >
        <div className='max-w-6xl w-full mx-auto sm:px-6 lg:px-8 py-12'>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-8 mt-4 md:mt-4'>
            {counters.map((counter, i) => (
              <div key={i} className='text-center'>
                <Avatar className={clsx(classes.avatar, 'mx-auto mb-2')}>
                  <img
                    className='w-8 h-8'
                    src={`/assets/icons/${counter.icon}`}
                    alt=''
                  />
                </Avatar>
                <p className='text-xl font-bold mt-2'>{counter.title}</p>
                <p className='text-sm mt-4'>{counter.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div
        id='payment-partners'
        className='pt-12 px-4 mt-4 mb-16 md:mb-4 bg-white text-center'
      >
        <div className='max-w-6xl mx-auto sm:px-6 lg:px-8'>
          <p
            className={clsx(
              classes.header,
              'text-2xl md:text-3xl leading-8 tracking-tight font-extrabold'
            )}
          >
            Payment Partners
          </p>

          <div className='grid grid-cols-1 md:grid-cols-4 gap-y-8 gap-x-4 mt-4'>
            {products.slice(0, 4).map((product, i) => (
              <Card key={i} className={classes.card} elevation={1}>
                <CardContent>
                  <div className='flex items-center justify-between h-full'>
                    <img
                      className='w-full h-full'
                      src={`/assets/icons/${product.icon}`}
                      alt=''
                    />

                    {!product.disableText && (
                      <h2 className='text-gray-900 text-2xl font-extrabold'>
                        {product.name}
                      </h2>
                    )}
                  </div>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            ))}
          </div>
          <div className='mt-4'>
            <p className='text-gray-600 font-extralight tracking-tight'>
              The easiest way to make payment
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    loading: auth.register.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      register: authActions.register,
    },
    dispatch
  )
}

export default withReducer(
  'homeApp',
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Home))
