import React from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

const pages = [
  { name: 'Home', path: '/', icon: 'home' },
  { name: 'About Us', path: '#footer', icon: 'list' },
  { name: 'Features', path: '#features', icon: 'featured_play_list' },
  { name: 'FAQ', path: '/', icon: 'live_help' },
  { name: 'Contact', path: '#footer', icon: 'contact_support' },
]

const authPages = [
  { name: 'Profile', path: '/profile', icon: 'person' },
  { name: 'Logout', path: '/logout', icon: 'exit_to_app' },
]

export default function NavigationList(props) {
  const { user } = props

  return (
    <div>
      <List>
        {pages.map((page, index) => (
          <ListItem button key={page.name} component={Link} to={page.path}>
            <ListItemIcon>
              <Icon>{page.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {user.role ? (
        <List>
          {authPages.map((page, index) => (
            <ListItem button key={page.name} component={Link} to={page.path}>
              <ListItemIcon>
                <Icon>{page.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItem button>
            <ListItemIcon>
              <Icon>lock_open</Icon>
            </ListItemIcon>
            <ListItemText primary='Login' />
          </ListItem>
        </List>
      )}
    </div>
  )
}
