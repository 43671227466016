import authService from "./../../../services/authService";
import { setUserData } from "./user.actions";
import { showSnackbar } from "./../../../store/actions";

export const REGISTER_SUCCESS = "[AUTH] REGISTER_SUCCESS_2";
export const REGISTER_ERROR = "[AUTH] REGISTER_ERROR";
export const REGISTER_PROGRESS = "[AUTH] REGISTER_PROGRESS";

export function register(data) {
  return (dispatch) => {
    dispatch({ type: REGISTER_PROGRESS });
    authService
      .createUser(data)
      .then((user) => {
        console.log(user, "now register action user");
        Promise.all([
          dispatch(setUserData(user)),
          dispatch({ type: REGISTER_SUCCESS }),
        ]).then(dispatch(showSnackbar({ message: "Registration successful" })));
      })
      .catch((error) => {
        console.log(error, "register error");
        dispatch({
          type: REGISTER_ERROR,
          payload: error.response.message,
        });
      });
  };
}
