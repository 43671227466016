import React from 'react';

function Button({ updateJob }) {
  console.log('Rendering Update Job button');
  
  return (
    <div>
      <button onClick={updateJob}>Update Job</button>
    </div>
  );
}

export default Button;
