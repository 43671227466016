import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function Policy(props) {
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, 'w-full')}>
      <div
        id='features'
        className='max-w-6xl mx-auto mt-0 md:mt-24 px-4 py-4 sm:px-6 lg:px-8'
      >
        <div className='grid grid-cols-6 gap-8 py-2'>
          <div className='col-span-6 md:col-span-46'>
            <h2 className='text-1xl sm:text-3xl text-blue-900 mb-4'>
              GDPR Privacy Policy of EasyGo
            </h2>
            <div className=''>
              <p className='text-gray-800 leading-normal'>
                EasyGo is committed to protecting and respecting your privacy.
                When you sign up and use EasyGo(via our website or Google Play
                Store), you accept the terms described within this agreement. We
                will not share your personal information with anyone except as
                described in this Privacy policy.
              </p>
              <p className='text-gray-800 leading-normal'>
                This Privacy and Cookies Policy is intended to meet our
                obligations under the ‘General Data Protection Regulation’ or
                GDPR, a legal framework that sets guidelines for the protection
                and processing of personal information of individuals within the
                European Union. We have adopted GDPR as the data privacy
                standard for all our operating companies throughout the world.
              </p>
              <p className='text-gray-800 leading-normal'>
                Any modifications or changes to the Privacy Policy will be made
                on this page and you will receive notification through all our
                channels of service.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div id='about' className='pb-12 pt-4 md:pt-8 bg-white'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-0 md:mt-10'>
            <dl className='grid grid-cols-12'>
              <div className='flex self-stretch col-span-12 md:col-span-6 py-8'>
                <h1>EasyGo Policy</h1>
              </div>

              <div className='flex flex-grow col-span-12 md:col-span-6'>
                <img
                  className='w-full h-full'
                  src='/assets/diagram.svg'
                  alt=''
                />
              </div>
            </dl>
          </div>
        </div>
      </div> */}

      <div id='about' className='pb-8 pt-4 md:pt-4 bg-white'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-0 md:mt-4'>
            <div className='flex self-stretch pb-8'>
              <h1 className='text-blue-900'>
                Who We Are and How to Contact Us
              </h1>
            </div>

            <div>
              <p className='leading-normal'>
                EasyGo is a modern financial platform that serves as a financial
                companion to its users. EasyGo is a product of Optisoft
                Technology Company a leading software and mobile Application
                development company.{' '}
              </p>

              <div className='flex self-stretch py-8'>
                <h3 className='text-blue-900'>How to Contact Us</h3>
              </div>

              <p className='leading-normal'>
                If you have any questions about our practices or this Privacy
                Policy, please contact us at:
              </p>

              <p>info@easygo.com.ng and (+234) 708 895 7111</p>

              <div className='flex self-stretch py-8'>
                <h3 className='text-blue-900'>
                  Your Rights Relating to Your data
                </h3>
              </div>

              <p>
                You have the rights under this Privacy and Cookie Policy too:
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Request access to your data:
                </span>
                This enables you to receive a copy of the personal data we hold
                about you and to check that we are lawfully processing it.
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Request correction of the personal data that we hold about
                  you:
                </span>
                This enables you to have any incorrect or incomplete information
                about you corrected.
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Request erasure of your data:
                </span>{' '}
                This enables you to ask us to delete or remove Personal Data
                where there is no good reason for us continuing to process it or
                you object to us processing it
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Object to processing of your Personal Data:
                </span>{' '}
                This right exists where we are relying on legitimate interest as
                the legal basis for our processing and there is something about
                your situation, which makes you want to object to the processing
                of your Personal Data on this ground. You also have the right to
                object where we are processing your Data for direct marketing
                purposes.
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Request the restriction of processing of your Personal Data:
                </span>
                This enables you to ask us to suspend the processing of Personal
                Data about you, for example, if you want us to establish its
                accuracy or the reason for processing it.
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Request the transfer of your Personal Data:
                </span>{' '}
                We will provide to you, or a third party you have chosen, your
                Personal Data in a structured, commonly used, machine-readable
                format. Note that this right only applies to automated
                information which you initially provided consent for us to use
                or where we used the information to perform a contract with you.
              </p>

              <p className='py-2 leading-normal'>
                <span className='text-blue-900 font-bold mr-2'>
                  Withdraw consent:
                </span>{' '}
                This right only exists where we are relying on consent to
                process your Personal Data (“Consent Withdrawal”). If you
                withdraw your consent, we may not be able to provide you with
                access to certain specific functionalities of our Site. We will
                advise you if this is the case at the time you withdraw your
                consent.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id='about' className='pb-8 pt-4 md:pt-0 bg-white'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-0 md:mt-4'>
            <div className='flex self-stretch pb-8'>
              <h1 className='text-blue-900'>How to exercise your rights</h1>
            </div>

            <p className='leading-normal'>
              If you wish to exercise any of the rights above, please contact us
              using the details in Who we are and How to contact us.
            </p>
            <p className='leading-normal'>
              You will not have to pay a fee to access your data (or to exercise
              any right), however, we may charge a reasonable fee for consent
              withdrawal if your request is unfounded, repetitive, or excessive.
              In the event of these circumstances, we may refuse to comply with
              your request.
            </p>
            <p className='leading-normal'>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              Personal Data (or to exercise any of your other rights).
            </p>
            <p className='leading-normal'>
              This is a security measure to ensure that Personal Data is not
              disclosed to any person who has no right to receive it. We may
              also contact you to ask you for further information concerning
              your request to speed up our response.
            </p>
            <p className='leading-normal'>
              We try to respond to all legitimate requests within one month.
              Occasionally it may take us longer than a month if your request is
              particularly complex or you have made several requests. In this
              case, we will notify you and keep you updated.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Complaints</h3>
            </div>

            <p className='leading-normal'>
              If you would like to submit a complaint regarding this Privacy
              Policy or our practices concerning your Personal Data, please
              contact us at help@easygo.com.ng and (+234) 708 895 7111 We
              endeavor to reply to your complaint as soon as possible
            </p>
            <div className='flex flex-col self-stretch space-y-4 py-8 text-blue-900'>
              <h3>What personal data we collect</h3>
              <h4>Customer information</h4>
            </div>

            <p>
              To use EasyGo Services, you must provide customer information via
              our registration.
            </p>

            <p className='leading-normal'>
              This information includes, but is not limited to: Full name,
              e-mail address and phone number, address, male or female, date of
              birth, ID card and photograph (where applicable). Additional
              information may be requested depending on the user level to
              participate in social media functions on our site, promotions, or
              survey,s and when you contact us to report a problem on our site
            </p>

            <p className='leading-normal'>
              If you communicate with our Customer Services team via e-mail,
              telephone your conversations may be recorded and stored for
              training and record-keeping purposes. We use this information to
              measure and improve our Service quality.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Device information</h3>
            </div>

            <p className='leading-normal'>
              The following information may be collected from you automatically
              when you use the EasyGo app, but it is not limited to:
              <br />
              Details of your handset/device, unique device identifiers (IMEI or
              serial number), information about the SIM card, mobile network,
              operating system, and browser settings. We use this information to
              protect our customers from service-related crime, enhance the
              services we offer, and, help us understand how people use the
              Service.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Location information</h3>
            </div>

            <p className='leading-normal'>
              Certain EasyGo features may require location information from your
              device’s GPS. With your consent this information will be collected
              for these services. You will be required to grant consent. Turning
              off location services may render some services unavailable.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Analytics</h3>
            </div>

            <p className='leading-normal'>
              We may use in-app analytics technologies, like Google Analytics,
              to help improve and simplify the overall app, design, and service.
              These tools track aggregated information about app usage, provide
              performance measurements and allow better reporting on application
              failures.
            </p>

            <p>
              We record when you install or uninstall EasyGo App to help us
              track who is using the Service.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Tracking and Cookies</h3>
            </div>

            <p className='leading-normal'>
              A cookie is a string of information that a website stores on a
              visitor’s computer, and that the visitor’s The browser provides to
              the website each time the visitor returns.
            </p>

            <p className='leading-normal'>
              EasyGo uses cookies to help EasyGo identify and track visitors,
              their usage of the EasyGo website, and their website access
              preferences. EasyGo visitors who do not wish to have cookies
              placed on their computers should set their browsers to refuse
              cookies before using the websites or decline the option of using
              cookies when they visit for the first time. Our services may not
              function properly without the aid of cookies.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>
                Who We Share Your Personal Data With
              </h3>
            </div>

            <p className='leading-normal'>
              We may share your data with third parties as described in the
              table below. We consider this information to be a vital part of
              our relationship with you.
            </p>

            <p className='leading-normal'>
              Recipients Why we share it Our Affiliates Our affiliates may
              access your Data to help us develop, maintain and provide our
              Services and help manage our customer relationships (including
              providing customer support, customer liaison, fund advisory
              services, etc). Service Providers, Our service providers provide
              us support for our Services, including, for example, website and
              application development, hosting, maintenance, backup, storage,
              virtual infrastructure, payment processing, analysis, identity
              verification, background and compliance reviews, fund
              administration, banking services, and other services for us, which
              may require them to access or use Personal Data about you.
              Professional Advisers Our lawyers, accountants, bankers, auditors,
              and insurers may need to review your personal data to provide
              consultancy, compliance, banking, legal, insurance, accounting,
              and similar services. Legal and Taxing Authorities, Regulators,
              and Participants in Judicial Proceedings EasyGo may disclose your
              Data if we believe it is reasonably necessary to comply with a
              law, regulation, order, subpoena, rule of a self-regulatory
              organization or auditor to protect the safety of any person, to
              address fraud, security, or technical issues, or to protect our
              legal rights, interests, and the interests of others, such as, for
              example, in connection with the acquisition, merger, to enable
              them to interact with you and more effectively offer opportunities
              through the Site that are targeted towards your background and
              preferences. We may also allow third parties, including ad servers
              or ad networks, to serve advertisements on the Site, and such
              third parties may be provided with access to your Data to provide
              advertising tailored to your interests. Researchers To enhance the
              public understanding of patterns and trends in the markets served
              by or Services, we may provide Personal Data to third parties
              under confidentiality obligations such as, for example, academics
              or contractors for research purposes. API Users A limited number
              of partners have API access to portions of the Site. Examples of
              the most common API uses are OAuth and AML/accreditation
              verification of potential investors.
            </p>

            <div className='flex flex-col space-y-4 self-stretch py-8 text-blue-900'>
              <h2>How we use your data and why</h2>
              <h3>Payment information</h3>
            </div>

            <p className='leading-normal'>
              To process payments on EasyGo, we need to share some of your
              personal information with the person or company with whom you are
              transacting. This information may include:
            </p>
            <p className='leading-normal'>
              Contact information (mobile number, e-mail address, personal
              photo) Payment information (card details, banking information) We
              will not expose your credit/debit card number or bank account
              number to anyone you have paid or who has paid you through EasyGo,
              except with your express permission or if we are required to do so
              to comply with a subpoena or other legal action.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Identity information</h3>
            </div>

            <p className='leading-normal'>
              We collect and store personal information about you to comply with
              the relevant financial regulations. Apart from the circumstance of
              complying with a Court Order, Arbitral Panel, Tribunal, Regulatory
              Directive or Order or any other legal or regulatory obligation, we
              do not disclose information about identifiable individuals to
              other parties, but we may provide them with anonymous aggregate
              information, such as the number of people of a certain age or sex
              who have performed certain transactions of a certain amount within
              a particular timeframe, for example.
            </p>

            <p className='leading-normal'>
              Where we use a 3rd party service, with your consent, we may share
              your information to offer the service or improve the experience of
              that service. When you use such a service for the first time, you
              will need to review and agree to their terms and conditions and
              privacy policy. In respect of the GDPR
            </p>

            <p className='leading-normal'>
              In respect of each of the purposes for which we use your Personal
              Data, the GDPR requires us to ensure that we have a legal basis
              for that use. The legal basis depends on the Services you use and
              how you use them. This means we collect and use your Personal Data
              only where:
            </p>

            <p className='leading-normal'>
              We need it to provide you the Services, including to operate the
              Services, provide customer support and personalized features, and
              protect the safety and security of the Services; It satisfies a
              legitimate interest (which is not overridden by your data
              protection interests), such as for research and development, to
              market and promote the Services and to protect our legal rights
              and interests; or We need to process your data to comply with a
              legal or regulatory obligation. We may also rely on your consent
              as a legal basis for using your Personal Data where we have
              expressly sought it for a specific purpose. If we do rely on your
              consent to use your Personal Data, you have the right to change
              your mind at any time (but this will not affect any processing
              that has already taken place).
            </p>

            <div className='flex self-stretch py-8'>
              <h2 className='text-blue-900'>
                What happens when you do not provide necessary personal data?
              </h2>
            </div>

            <p className='leading-normal'>
              Where we need to process your Personal Data either to comply with
              the law or to perform the terms of a contract we have with you and
              you fail to provide that data when requested, we may not be able
              to perform the contract we have or are trying to enter into with
              you (for example, to provide you with the functionalities of the
              Services). In this case, we may have to stop providing you with
              our Services.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>
                How Information may be disclosed
              </h3>
            </div>

            <p>Your information may be shared in the following cases:</p>
            <ul>
              <li className='flex items-center py-2'>
                <CheckIcon className='mr-2 flex-shrink-0' />
                <span>
                  to fulfill a request from the regulator or government entity
                  with sufficient authority
                </span>
              </li>
              <li className='flex items-center py-2'>
                <CheckIcon className='mr-2 flex-shrink-0' />
                <span>
                  to fulfill other legal obligations such as a request from a
                  court
                </span>
              </li>
              <li className='flex items-center py-2'>
                <CheckIcon className='mr-2 flex-shrink-0' />
                <span>for regulatory reporting purposes</span>
              </li>
            </ul>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Business transfer</h3>
            </div>

            <p className='leading-normal'>
              In the unlikely event that EasyGo goes into administration or is
              acquired, a business transfer may occur. You consent to the
              successor company having access to the information maintained by
              EasyGo, including customer Account Information, and such successor
              company would continue to be bound by this Privacy Policy unless
              and until it is amended.
            </p>

            <div className='flex self-stretch py-8'>
              <h2 className='text-blue-900'>
                Where your personal information is stored
              </h2>
            </div>

            <p className='leading-normal'>
              EasyGo is entirely committed to protecting the information we
              collect from you. We maintain appropriate administrative,
              technical, and physical safeguards (i.e. firewalls, data
              encryption, competent security guards, etc). These processes are
              in place to prevent accidental, unlawful, or unauthorized
              destruction, loss, alteration, access, disclosure, or use of your
              information.
            </p>

            <p className='leading-normal'>
              EasyGo is required to retain the details of transactions or
              payments you make via the EasyGo app after the transaction is
              completed. We will store these transactions for the required
              period under the financial regulations of your jurisdiction.
            </p>

            <div className='flex self-stretch py-8'>
              <h2 className='text-blue-900'>
                How long we store your Personal Data
              </h2>
            </div>

            <p className='leading-normal'>
              We will retain your information for as long as your account is
              active, or it is reasonably needed for the purposes set out in How
              We Use Your Personal Data and Why unless you request that we
              remove your Personal Data as described in Your Rights Relating to
              Personal Data. We will only retain your Personal Data for so long
              as we reasonably need to use it for these purposes unless a longer
              retention period is required by law (for example for regulatory
              purposes). This may include keeping your Personal Data after you
              have deactivated your account for the period needed for us to
              pursue legitimate business interests, conduct audits, comply with
              (and demonstrate compliance with) legal obligations, resolve
              disputes, and enforce our agreements.
            </p>

            <div className='flex self-stretch py-8'>
              <h2 className='text-blue-900'>Links to Other Websites</h2>
            </div>

            <p className='leading-normal'>
              This Privacy & Cookies Policy applies only to the services. The
              Services may contain links to other websites not operated or
              controlled by EasyGo. We are not responsible for the content,
              accuracy, or opinions expressed in such websites, and such
              websites are not investigated, monitored, or checked for accuracy
              or completeness by us. Please remember that when you use a link to
              go from Services to another website, our Privacy and Cookies
              Policy is no longer in effect. Your browsing and interaction on
              any other website, including those that have a link on our site,
              is subject to the website’s own rules and policies. Such third
              parties may use their cookies or other methods to collect
              information about you.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Your responsibilities</h3>
            </div>

            <p className='leading-normal'>
              You are responsible for the protection of your password or PIN in
              its entirety as communicated in our Terms and Conditions. You must
              not share your EasyGo password or PIN with anyone.
            </p>

            <p className='leading-normal'>
              We will never request your PIN or password via email or SMS or any
              other means. In the event you receive such communication, please
              disregard and contact us at info@easygo.com.ng and 07088957111.
            </p>

            <p className='leading-normal'>
              In the event you share your password with a third party, you will
              be held responsible for the consequences. If you believe your
              password or PIN has been compromised, please change it immediately
              and contact us.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Changes to this Privacy Policy</h3>
            </div>

            <p className='leading-normal'>
              EasyGo may change its Privacy Policy from time to time, and at
              EasyGo’s sole discretion. We will notify you when changes have
              been made. By continuing to use the mobile application or website
              after the change, you agree to accept the new policy.
            </p>

            <div className='flex self-stretch py-8'>
              <h3 className='text-blue-900'>Contact Us</h3>
            </div>

            <p>
              EasyGo ensures that our day-to-day processes comply with this
              Privacy Policy.
            </p>
            <p className='leading-normal'>
              To contact us please send an email to info@easygo.com.ng and
              07088957111. <br />
              <strong>Formation</strong> refers to any confidential and/or
              personally identifiable or other information related to users of
              EasyGo services.
            </p>

            <p className='leading-normal'>
              This Privacy and Cookies Policy is intended to meet our
              obligations under the ‘General Data Protection Regulation’ or
              GDPR, a legal framework that sets guidelines for the protection
              and processing of personal information of individuals within the
              European Union. We have adopted GDPR as the data privacy standard
              for all our operating companies throughout the world.
            </p>

            <p className='leading-normal'>
              Any modifications or changes to the Privacy Policy will be made on
              this page and you will receive notification through all our
              channels of service.
            </p>
          </div>
        </div>
      </div>

      <div id='about' className='pb-12 pt-4 md:pt-8 bg-white'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='mt-0 md:mt-10'></div>
          <div className='border-t border-gray-200 mb-24'>
            <dl>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  <Typography variant='h6'>
                    Category of Personal Data collected
                  </Typography>
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <Typography variant='h6'>What this means</Typography>
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Identity Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  First name, surname, maiden name, last name, username or
                  similar identifier, marital status, title, date of birth and
                  gender, selfie picture, identification document number, copies
                  of ID documents, or other forms of identification.
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Contact Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Your home address, work address, billing address, email
                  address, and telephone numbers.
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Online Presence Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Links to your public account pages at social media websites,
                  links to personal websites, and other online materials related
                  to you.
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Financial Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Your bank account and payment card details, statements about
                  your wealth, and financial situation.
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Transaction Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Any details about payments to and from you and other details
                  of subscriptions and services you have purchased from us. Data
                  in respect of your transactions with third parties (including
                  your credit history).
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Content Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Any content you post to the Services not already included in
                  another category, including without limitation, your profiles,
                  questions, preference settings, answers, messages, comments,
                  and other contributions on the Services, and metadata about
                  them (such as when you posted them) (“Content”).
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Behavioral Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Inferred or assumed information relating to your behavior and
                  interests, based on your online activity. This is most often
                  collated and grouped into “segments.”
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>
                  Technical Data
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  Security credentials (username and PIN/password) Internet
                  protocol (IP) address, your login data, browser type, and
                  version, time zone setting and location, browser plug-in types
                  and versions, operating system and platform, and other
                  technology on the devices you use to access this application
                  or website or use our services.
                </dd>
              </div>
            </dl>

            <p className='leading-normal'>
              This personal data will be used to assist us in delivering the
              service that you signed up for; allow us to understand how
              services are being used by you; protect you and your account;
              improve our services and communicate new services and offers to
              you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;
